const Footer = () => {
    return (
        <>
            <footer className="bg_img" data-background="assets/images/footer/footer-bg.jpg">
                <div className="footer-top">
                    <div className="container">
                        <div className="row gy-4 mb--50">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-widget widget-about">
                                    <h5 className="title">About Us</h5>
                                    <p>Acharya Chankya Mahavidyalya faculty of Pharmacy run by the Acharya Chankya Mahavidyalya Samiti with the entire infrastructure essential for a Pharmacy College as per the norms of PCI</p>
                                    <ul className="contact">
                                        <li><i className="fas fa-headphones-alt"></i>+91-7266837835</li>
                                        <li><i className="fas fa-home"></i>Sultanpur UP</li>
                                        <li><i className="fas fa-envelope"></i>office.acmpharmacy@gmail.com </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-widget widget-blog">
                                    <h5 className="title">Important Links</h5>
                                    <ul className="">
                                        <li><a href="https://bteup.ac.in/webapp/defaultnew.aspx">BTEUP</a></li>
                                        <li><a href="https://pci.nic.in/">PCI</a></li>
                                        <li><a href="https://antiragging.in/">Anti Ragging</a></li>
                                        <li><a href="https://dgpm.nic.in/4205/stud_registration.do">PCI Student Registration</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 pl-xl-4">
                                <div className="footer-widget widgt-form">

                                    <h5 className="title">National Anti Ragging Help Line</h5>
                                    <p>1800-180-5522</p>
                                    <p>24x7 Toll Free Number</p>
                                    <p>helpline@antiragging.in</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <p>
                            &copy; 2022 All Rights Reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;