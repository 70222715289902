import Footer from "../component/Footer";
import Header from "../component/Header";
import Form from 'react-bootstrap/Form';
import setting from "../setting.json";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";

const schema = yup.object().shape({
    stu_name: yup.string().required("Fill value"),
    adm_no: yup.string().required("Fill value"),
    phone: yup.string().required("Fill value"),
    address: yup.string().required("Fill value"),
    message: yup.string().required("Fill value"),

});

const Feedback = () => {

    const { register, reset, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });

    const [message, setMessage] = useState('');

    const saveFeedback = async (data) => {
        let sr = {
            "stu_name": data.stu_name,
            "adm_no": data.adm_no,
            "phone": data.phone,
            "address": data.address,
            "message": data.message
        }
        await fetch(setting.api + "api/saveFeedback", {
            body: JSON.stringify(sr),
            method: "post",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(u => {
                console.log(u);
                setMessage("Your Request submitted successfully.")
                reset({
                    stu_name: '',
                    adm_no: '',
                    phone: '',
                    address: '',
                    message: '',
                });

            });
    }

    return (
        <>
            <Header />

            <section className="page-header bg_img"
                style={{
                    backgroundImage: "URL(./images/banner2.jpg)",
                    height: "30lvh",
                }}
            >
                <div className="container">
                    <div className="page-header-content">
                        <h1 className="title">Feedback</h1>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>

                            <li> Feedback </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="feedback-title">Feedback</h3>
                        <p className="message">{message}</p>
                        <br />
                        <Form onSubmit={handleSubmit(saveFeedback)}>

                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="Student Name" className="text"
                                    {...register("stu_name")}
                                />
                                <p>{errors.stu_name?.message}</p>

                            </Form.Group>

                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="Admission No." className="text"
                                    {...register("adm_no")}
                                />
                                {errors.adm_no && <p>{errors.adm_no.message}</p>}
                            </Form.Group>

                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="Student Phone" className="text"
                                    {...register("phone")}
                                />
                                {errors.phone && <p>{errors.phone.message}</p>}
                            </Form.Group>

                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="Address" className="text"
                                    {...register("address")}
                                />
                                {errors.address && <p>{errors.address.message}</p>}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" rows={3} placeholder="Enter Message"
                                    {...register("message")}
                                />
                                {errors.message && <p>{errors.message.message}</p>}
                            </Form.Group>

                            <div className="form-group">
                                <button type="submit" className="d-block custom-button">
                                    <span>Submit Now</span>
                                </button>
                            </div>

                        </Form>



                    </div>
                </div>
            </div >

            <Footer />
        </>
    );
}

export default Feedback;