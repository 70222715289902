import React from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { Row, Col, Form, Button } from "react-bootstrap";
import setting from "../setting.json";
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
    course_id: yup.string().required("Fill value"),
    part_id: yup.string().required("Fill value"),
    acd_year: yup.string().required("Fill value"),

});

const Examination = () => {

    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });

    const [academicList, setAcademicList] = useState();
    const [coursecList, setCoursecList] = useState();
    const [samesterList, setSamesterList] = useState();
    const [list, setList] = useState([]);
    const [data, setData] = useState([]);
    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(20);


    const getAcademicYear = async () => {
        await fetch(setting.api + "api/getAcademicYear", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setAcademicList(u);

            });
    }

    const getAllCourse = async () => {
        await fetch(setting.api + "api/getAllCourse", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setCoursecList(u);

            });
    }

    const getYearSem = async (e) => {
        var id = e.target.value;
        await fetch(setting.api + "api/getYearSem?cid=" + id, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setSamesterList(u);

            });
    }

    const getExaminationRecord = async (data) => {
        console.log(data);
        let sr = {
            "course_id": data.course_id,
            "part_id": data.part_id,
            "acd_year": data.acd_year
        }
        await fetch(setting.api + "api/getExaminationRecord", {
            body: JSON.stringify(sr),
            method: "post",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(u => {
                setList(u.data);

            });
    }

    useEffect(() => {

        getAcademicYear();
        getAllCourse();
        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, []);

    // useEffect(() => {
    //     getStudentRecord();
    // }, [updated]);

    useEffect(() => {

        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [list]);

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);
    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);

    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }


    const percentage = (marks, total) => {
        return (marks * 100) / total;
    }
    console.log(percentage);

    return (
        <>
            <Header />

            <div class="header-form">
                <div class="bg-lay">
                    <div class="cross">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
                <form class="form-container">
                    <input type="text" placeholder="Input Your Search" name="name" />
                    <button type="submit">Search</button>
                </form>
            </div>

            {/* <div class="cart-sidebar-area">
                <div class="top-content">
                    <a href="index-2.html" class="logo">
                        <img src="assets/images/logo/logo.png" alt="logo" />
                    </a>
                    <span class="side-sidebar-close-btn">
                        <i class="fas fa-times"></i>
                    </span>
                </div>
                <div class="bottom-content">
                    <div class="cart-products">
                        <h4 class="title">Shopping cart</h4>
                        <div class="single-product-item">
                            <div class="thumb">
                                <img src="assets/images/shop/shop01.png" alt="shop" />
                            </div>
                            <div class="content">
                                <h4 class="title">Color Pencil</h4>
                                <div class="price">
                                    <span class="pprice">$80.00</span>
                                    <del class="dprice">$120.00</del>
                                </div>
                                <a href="#" class="remove-cart">Remove</a>
                            </div>
                        </div>
                        
                        <div class="single-product-item">
                            <div class="thumb">
                                <img src="assets/images/shop/shop02.png" alt="shop" />
                            </div>
                            <div class="content">
                                <h4 class="title">Water Pot</h4>
                                <div class="price">
                                    <span class="pprice">$80.00</span>
                                    <del class="dprice">$120.00</del>
                                </div>
                                <a href="#" class="remove-cart">Remove</a>
                            </div>
                        </div>
                        <div class="single-product-item">
                            <div class="thumb">
                                <img src="assets/images/shop/shop03.png" alt="shop" />
                            </div>
                            <div class="content">
                                <h4 class="title">Art Paper</h4>
                                <div class="price">
                                    <span class="pprice">$80.00</span>
                                    <del class="dprice">$120.00</del>
                                </div>
                                <a href="#" class="remove-cart">Remove</a>
                            </div>
                        </div>
                        <div class="single-product-item">
                            <div class="thumb">
                                <img src="assets/images/shop/shop04.png" alt="shop" />
                            </div>
                            <div class="content">
                                <h4 class="title">Stop Watch</h4>
                                <div class="price">
                                    <span class="pprice">$80.00</span>
                                    <del class="dprice">$120.00</del>
                                </div>
                                <a href="#" class="remove-cart">Remove</a>
                            </div>
                        </div>
                        <div class="single-product-item">
                            <div class="thumb">
                                <img src="assets/images/shop/shop05.png" alt="shop" />
                            </div>
                            <div class="content">
                                <h4 class="title">Comics Book</h4>
                                <div class="price">
                                    <span class="pprice">$80.00</span>
                                    <del class="dprice">$120.00</del>
                                </div>
                                <a href="#" class="remove-cart">Remove</a>
                            </div>
                        </div>
                        <div class="btn-wrapper text-center">
                            <a href="#" class="custom-button">
                                <span>Checkout</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}

            <section class="page-header bg_img"
                style={{
                    backgroundImage: "URL(./images/banner2.jpg)",
                    height: "30lvh",
                }}
            >
                <div class="container">
                    <div class="page-header-content">
                        <h1 class="title">Examination</h1>
                        <ul class="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li> Examination </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div class="shop-cart padding-top padding-bottom">
                <div class="container">
                    <div class="cart-checkout-box mb-widget">
                        <Form onSubmit={handleSubmit(getExaminationRecord)} >
                            <Row >
                                <Col>
                                    <Form.Group>
                                        <Form.Select aria-label="Default select example"
                                            {...register("acd_year")}
                                        >
                                            <option>Academic Year</option>
                                            {
                                                academicList && academicList.map((a) =>
                                                    <option key={a.yr_id} value={a.yr_id}>{a.start_year + '-' + a.end_year}</option>
                                                )
                                            }
                                        </Form.Select>
                                        {errors.acd_year && <p>{errors.acd_year.message}</p>}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Select aria-label="Default select example" {...register("course_id")}
                                            onChange={(e) => getYearSem(e)}
                                        >
                                            <option>Course</option>
                                            {
                                                coursecList && coursecList.map((a) =>
                                                    <option key={a.brc_id} value={a.brc_id}>{a.course}</option>
                                                )
                                            }
                                        </Form.Select>
                                        {errors.course_id && <p>{errors.course_id.message}</p>}
                                    </Form.Group>
                                </Col >
                                <Col>
                                    <Form.Group>
                                        <Form.Select aria-label="Default select example"
                                            {...register("part_id")}

                                        >
                                            <option>Semester</option>
                                            {
                                                samesterList && samesterList.map((a) =>
                                                    <option key={a.base_id} value={a.base_id}>{a.base_name}</option>
                                                )
                                            }
                                        </Form.Select>
                                        {errors.part_id && <p>{errors.part_id.message}</p>}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Button type="submit" variant="secondary">Submit</Button>

                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div class="cart-top mb-widget">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Roll No.</th>
                                    <th>Name</th>
                                    <th>Father</th>
                                    <th>Total Marks</th>
                                    <th>Marks obtained</th>
                                    <th>Percentage</th>
                                </tr>
                            </thead>
                            <tbody id="result">

                                {data.length > 0 && data.map((a, i) =>
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{a.admit_card_issue}</td>
                                        <td>{a.stu_fname + ' ' + a.stu_mname + ' ' + a.stu_lname}</td>
                                        <td>{a.stu_father_fname + ' ' + a.stu_father_mname + ' ' + a.stu_father_lname}</td>
                                        <td>{a.total_marks}</td>
                                        <td>{a.marks_obtained}</td>
                                        <td>{((a.marks_obtained * 100) / a.total_marks).toFixed(2)} %</td>
                                    </tr>
                                )}
                                {data.length == 0 &&
                                    <tr >
                                        <td colSpan={7}>No Record found</td>
                                    </tr>
                                }

                            </tbody>
                        </table>
                        {data.length > 0 &&
                            <div className='page'>

                                <Button variant='secondary' className='btn' size="sm" onClick={clickFirst}>{`<<`}</Button>{' '}
                                <Button variant='secondary' className='btn' size="sm" onClick={clickPrev}>{`<`}</Button>
                                <span >page {currentPage} of {pageCount}</span>
                                {currentPage < pageCount &&
                                    < Button variant='secondary' className='btn' size="sm" onClick={clickNext}>
                                        {`>`}
                                    </Button>
                                }
                                {' '}
                                {currentPage < pageCount &&
                                    <Button variant='secondary' className='btn' size="sm" onClick={clickLast}>{`>>`}</Button>
                                }

                            </div>
                        }

                    </div>
                </div>
            </div >

            <Footer />
        </>
    );
}

export default Examination;