import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Card from "react-bootstrap/Card";
import "./Gallery.css";

function Gallery() {
  return (
    <div>
      <Header />
      {/* <div class="header-form">
        <div class="bg-lay">
          <div class="cross">
            <i class="fas fa-times"></i>
          </div>
        </div>
        <form class="form-container">
          <input type="text" placeholder="Input Your Search" name="name" />
          <button type="submit">Search</button>
        </form>
      </div> */}

      <section
        class="page-header bg_img"
        style={{
          backgroundImage: "URL(./images/banner2.jpg)",
          height: "30lvh",
          }}
      >
        <div class="container">
          <div class="page-header-content">
            <h1
              class="title text-light"
              style={{
                WebkitTextStrokeColor: "black",
                WebkitTextStrokeWidth: "1px",
              }}
            >
              Gallery
            </h1>
            <ul class="breadcrumb">
              <li>
                <a className="text-light" href="index-2.html">
                  Home
                </a>
              </li>
              <li className="text-light"> Gallery</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="faqs-single-section padding-top padding-bottom">
        <div class="container">
          <h3>
            <img
              src="./images/picture.png"
              alt=""
              height={40}
              className="px-2"
            />
            Gallery{" "}
            <img
              src="./images/picture.png"
              alt=""
              height={40}
              className="px-2"
            />
          </h3>
          <br />
          <br />
          <div className="row">
            <div className=" col-lg-4 col-md-4 col-sm-6 col-xs-12  ">
              <Card className="img-card shadow-risen">
                <Card.Img variant="top" src="./images/comm1.jpg" />
              </Card>
            </div>

            <div className=" col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <Card className="img-card">
                <Card.Img variant="top" src="./images/comm2.jpg" />
              </Card>
            </div>

            <div className=" col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <Card className="img-card">
                <Card.Img variant="top" src="./images/comm3.jpg" />
              </Card>
            </div>
            <br />
            <span></span>
            <br />
            <div className=" col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <Card className="img-card">
                <Card.Img variant="top" src="./images/comm4.jpg" />
              </Card>
            </div>

            <div className=" col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <Card className="img-card">
                <Card.Img variant="top" src="./images/comm5.jpg" />
              </Card>
            </div>

            <div className=" col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <Card className="img-card">
                <Card.Img variant="top" src="./images/comm6.jpg" />
              </Card>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Gallery;
