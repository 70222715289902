import React, { useState } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import Form from 'react-bootstrap/Form';
import setting from "../setting.json";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
    stu_name: yup.string().required("Fill value"),
    email: yup.string().required("Fill value"),
    message: yup.string().required("Fill value"),

});
const Contact = () => {

    const { register, reset, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });

    const [message, setMessage] = useState('');

    const saveContactQuery = async (data) => {
        let sr = {
            "stu_name": data.stu_name,
            "email": data.email,
            "message": data.message
        }
        await fetch(setting.api + "api/saveContactQuery", {
            body: JSON.stringify(sr),
            method: "post",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(u => {
                console.log(u);
                setMessage("Your Request has been submitted successfully.")
                reset({
                    stu_name: '',
                    email: '',
                    message: '',
                });

            });
    }


    return (

        <>
            <Header />
            <section className="page-header bg_img"
                style={{
                    backgroundImage: "URL(./images/banner2.jpg)",
                    height: "30lvh",
                }}
            >
                <div className="container">
                    <div className="page-header-content">
                        <h1 className="title">Contact Page</h1>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>

                            <li> Contact </li>

                        </ul>
                    </div>
                </div>
            </section>
            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="Contact-title">Contact</h3>
                        <p className="message">{message}</p>

                        <Form onSubmit={handleSubmit(saveContactQuery)}>
                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="User Name" className="text" {...register("stu_name")} />
                                {errors.stu_name && <p>{errors.stu_name.message}</p>}
                            </Form.Group>

                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="Email" className="text"
                                    {...register("email")} />
                                {errors.email && <p>{errors.email.message}</p>}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" rows={3} placeholder="Enter Message"
                                    {...register("message")} />
                                {errors.message && <p>{errors.message.message}</p>}
                            </Form.Group>

                            <div className="form-group">
                                <button type="submit" className="d-block custom-button">
                                    <span>Submit Now</span>
                                </button>
                            </div>

                        </Form>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Contact;