import Footer from "../component/Footer";
import Header from "../component/Header";
import setting from '../setting.json';

const GenderHarassment = () => {
    return (
        <>
            <Header />

            <section class="page-header bg_img"
                style={{
                    backgroundImage: "URL(./images/banner2.jpg)",
                    height: "30lvh",
                }}
            >
                <div class="container">
                    <div class="page-header-content">
                        <h1 class="title">Gender Harassment</h1>
                        <ul class="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li> Gender Harassment </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section class="faqs-single-section padding-top padding-bottom">
                <div class="container">
                    <div class="row ">
                        <div class="col-lg-12">

                            <embed src={`${setting.api}uploads/docs/gender_sensitization.pdf#toolbar=0&navpanes=0&scrollbar=0&view=fitH,100`} type="application/pdf" frameBorder="0" scrolling="auto" height="2100px" width="100%">
                            </embed>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default GenderHarassment;