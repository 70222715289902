import Footer from "../component/Footer";
import Header from "../component/Header";

const Academic = () => {
    return (
        <>
            <Header />
            <div class="header-form">
                <div class="bg-lay">
                    <div class="cross">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
                <form class="form-container">
                    <input type="text" placeholder="Input Your Search" name="name" />
                    <button type="submit">Search</button>
                </form>
            </div>

            <section class="page-header bg_img"
                style={{
                    backgroundImage: "URL(./images/banner2.jpg)",
                    height: "30lvh",
                }}
            >
                <div class="container">
                    <div class="page-header-content">
                        <h1 class="title">Academic Details</h1>
                        <ul class="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li> Academic Details </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="faqs-single-section padding-top padding-bottom">
                <div class="container">
                    <div class="row mb--10">
                        <div class="col-lg-8">
                            <div class="faq-wrapper">
                                <div class="faq-item active open">
                                    <div class="faq-title">
                                        <span class="right-icon"></span>
                                        <h5 class="academic-title">Course</h5>
                                    </div>
                                    <div class="faq-content">
                                        <p align="justify">
                                            <b>Pharmacy</b> is the science and technique of preparing and dispensing drugs. It is a health profession that links health sciences with chemical sciences and aims to ensure the safe and effective use of pharmaceutical drugs.
                                        </p>
                                    </div>
                                </div>
                                <div class="faq-item active open">
                                    <div class="faq-title">
                                        <span class="right-icon"></span>
                                        <h5 class="academic-title">D. Pharm</h5>
                                    </div>
                                    <div class="faq-content">
                                        <p align="justify">Diploma in Pharmacy (D.Pharm) is an under graduate diploma programme in the medicinal field of Pharmacy which enables a candidate to know the basic concepts of pharmaceutical science. After the completion of D.Pharm course, the candidates become a registered pharmacist and have a huge career scope both in government as well as private organizations.</p>
                                    </div>
                                </div>
                                <div class="faq-item active open">
                                    <div class="faq-title">
                                        <span class="right-icon"></span>
                                        <h5 class="academic-title">Duration of course : 02 years</h5>
                                    </div>
                                    <div class="faq-content">
                                        <p align="justify">he course will extend over a period of two academic years with each academic year spread over a period of not less than one hundred and eighty working days. There shall be an examination in the first year on the subjects of Part-I and an examination in the second year comprising the Part-II subjects. The course of study for D.Pharm Part-1 and D.Pharm part-II shall include the subjects as given in below..</p>
                                    </div>
                                </div>
                                <div class="faq-item active open">
                                    <div class="faq-title">
                                        <span class="right-icon"></span>
                                        <h5 class="academic-title">Eligibility for Admission</h5>
                                    </div>
                                    <div class="faq-content">
                                        <p align="justify">Candidate who have passed the Higher Secondary Exam (+2) of the any state board under Indian government with Physics, Chemistry and Biology or Mathematics as optional Subjects and equivalent, are eligible to apply.</p>
                                    </div>
                                </div>
                                <div class="faq-item active open">
                                    <div class="faq-title">
                                        <span class="right-icon"></span>
                                        <h5 class="academic-title">Selection Criteria</h5>
                                    </div>
                                    <div class="faq-content">
                                        <p align="justify">Through Counselling Admission: Students who have successfully cleared his/her Joint Entrance Examination Conducted by BTE UP may opt the institute through BTE counselling.Direct Admission on Vacant/Management Seats To avail the seats of management quota student must have passed 12th examination with Physics, Chemistry & Biology/Math with a minimum of 50% marks (for SC/ST 45%).</p>
                                    </div>
                                </div>
                                <div class="faq-item active open">
                                    <div class="faq-title">
                                        <span class="right-icon"></span>
                                        <h5 class="academic-title">Practical Training</h5>
                                    </div>
                                    <div class="faq-content">
                                        <p align="justify">On successful completion of the course, the candidate will have to undergo 500 hours of practical training spread over a period of not less than 3 months in a Govt. Hospital or recognized institute approved for the purpose by Pharmacy Council of India.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-50">
                            <aside>
                                <div class="widget widget-feature">
                                    <div class="widget-header">
                                        <h5 class="academic-title">Course Features</h5>
                                    </div>
                                    <div class="widget-body">
                                        <ul>
                                            <li>
                                                <div class="ques cl-i-one">
                                                    <i class="fas fa-bell"></i>Course Name
                                                </div>
                                                <div class="ans">D.Pharm </div>
                                            </li>
                                            <li>
                                                <div class="ques cl-i-two">
                                                    <i class="fas fa-user"></i>Course Type
                                                </div>
                                                <div class="ans">Diploma</div>
                                            </li>
                                            <li>
                                                <div class="ques cl-i-three">
                                                    <i class="fas fa-sitemap"></i>Duration
                                                </div>
                                                <div class="ans">02 Years</div>
                                            </li>
                                            <li>
                                                <div class="ques cl-i-four">
                                                    <i class="fas fa-bus"></i>Eligibility
                                                </div>
                                                <div class="ans">10+2 PCM or PCB</div>
                                            </li>
                                            <li>
                                                <div class="ques cl-i-five">
                                                    <i class="fas fa-address-book"></i>No of Seat
                                                </div>
                                                <div class="ans">60</div>
                                            </li>
                                            <li>
                                                <div class="ques cl-i-six">
                                                    <i class="fas fa-clock"></i>Course Fees
                                                </div>
                                                <div class="ans">Rs. 45000/-</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Academic;