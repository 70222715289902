import { Link } from "react-router-dom";
import './Header.css';
import setting from '../setting.json';
const Header = () => {
    return (
        <>

            {/* <div className="overlayer">
                <div className="loader">
                    <div className="loader-inner"></div>
                </div>
            </div> */}

            <div className="scrollToTop"><i className="fas fa-angle-up"></i></div>
            <div className="overlay"></div>
            <div className="overlayTwo"></div>

            <header>




                <div className="header-bottom top">

                    <div className="container">
                        <div className=" row header-wrapper">
                            <div className="col-12 logo py-2" >
                                <Link to="#" className=" justify-content-center d-flex col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                    <img src="./images/ACM-logo.png" alt="logo-img" className="logo-img mx-5" />
                                    <div className=" col-head col-10">
                                        <h3 className="c-name">Acharya Chankya Mahavidyalya Faculty of Pharmacy</h3>

                                        <h4 className="c-place"> Sultanpur, Uttar Pradesh</h4>
                                    </div>

                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="header-bottom row">
                    <div className="container col-12 header-container" >
                        <div className="header-wrapper col-12" style={{ width: '100%', margin: '0px' }}>

                            <div className="menu-area col-12 py-0 px-3" style={{ width: '100%', margin: '0px' }}>
                                <ul className="menu ps-5">

                                    <li>
                                        <Link className="px-2" to="/"><i className="fas fa-home"></i>Home</Link>
                                    </li>

                                    <li>
                                        <Link className="px-2" to="/academic"><i className="fas fa-solid fa-book-open"></i>Academic</Link>
                                    </li>
                                    <li>
                                        <Link className="px-2" to="/faculty"><i className="fas fa-solid fa-users"></i>Faculty</Link>
                                    </li>

                                    <li>
                                        <Link className="px-2" to="#"><i className="fas fa-solid fa-user-secret"></i>Displinary Cell</Link>
                                        <ul className="submenu">
                                            <li><Link to="/antiRagging" >Anti Ragging</Link></li>
                                            <li><Link to="/committee_sc_st" >Anti Discrimination Cell</Link></li>
                                            <li><Link to="/placementCell" >Placement Cell</Link></li>
                                            <li><Link to="/genderHarassment" >Gender Senitization Cell</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link className="px-2" to="#"><i className="fas fa-solid fa-graduation-cap"></i>Student</Link>
                                        <ul className="submenu">
                                            <li><Link to="/examination" >Examination</Link></li>
                                            <li><Link to="/studentRecord" >Students Record</Link></li>
                                            <li><Link to="/studentComplaints" >Online Students Grievances</Link></li>
                                            <li>
                                                <Link to="/feedback">Feedback</Link>
                                            </li>
                                        </ul>
                                    </li>



                                    <li>
                                        <Link className="px-2" to="/contact"><i className="fas fa-solid fa-address-book"></i>Contact</Link>
                                    </li>
                                    <li>
                                        <Link className="px-2" to="#"><i className="fas fa-solid fa-download"></i>Download</Link>
                                        <ul className="submenu">
                                            <li><a href={`${setting.api}uploads/docs/new.pdf`} target="_blank">New Syllabus</a></li>
                                            <li><a href={`${setting.api}uploads/docs/old.pdf`} target="_blank">Old Syllabus</a></li>
                                            <li><a href={`${setting.api}uploads/docs/subject_01.pdf`} target="_blank">Subject 1st year</a></li>
                                            <li><a href={`${setting.api}uploads/docs/subject_02.pdf`} target="_blank">Subject 2nd Year </a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link className="px-2" to="/notification"><i className="fas fa-solid fa-newspaper"></i>News & Notification</Link>
                                    </li>
                                    <li>
                                        <Link className="px-2" to="/gallery"><i className="fas fa-solid fa-images"></i>Gallery</Link>
                                    </li>
                                </ul>
                                <div className="header-bar d-lg-none">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            </header >
        </>
    );
}

export default Header;